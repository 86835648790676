import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 400 }
  }

  submit(e) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, this.debounceValue)
  }
}