import { Controller } from '@hotwired/stimulus'
import Popover from 'bootstrap/js/dist/popover'

export default class extends Controller {
  connect() {
    this.popoverElement = new Popover(this.element, {
      html: true,
      trigger: this.element.getAttribute('data-bs-trigger') || 'hover',
      placement: this.element.getAttribute('data-bs-placement') || 'top'
    })
  }
}