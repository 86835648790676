import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'toggler', 'column' ]
  static values = { cookie: String }

  toggleColumn(e) {
    let toggler = e.srcElement

    if (toggler.checked) {
      this.showHideColumn(toggler.dataset.column, true)
    } else {
      this.showHideColumn(toggler.dataset.column, false)
    }

    this.updateCookie()
  }

  showHideColumn(columnName, columnActive) {
    const columnTargets = this.columnTargets.filter(target => target.dataset.columnName == columnName)

    columnTargets.forEach(target => {
      if (columnActive) {
        target.classList.remove('d-none')
      } else {
        target.classList.add('d-none')
      }
    })
  }

  updateCookie() {
    if (!this.hasCookieValue) { return }

    const activeColumns = this.togglerTargets
                              .filter(target => target.checked)
                              .map(target => target.dataset.column)

    const date = new Date();
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000)); //equivalent to 1 month
    const expires = 'expires=' + date.toUTCString();

    document.cookie = this.cookieValue + '=' + JSON.stringify(activeColumns) + ';' + expires + ';path=/';
  }
}