// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'


document.addEventListener('turbo:load', function(event) {
  // Toggle mobile sidebar
  let sidebarToggle = document.getElementById('sidebar-toggle')
  if (sidebarToggle) {
    sidebarToggle.addEventListener('click', function(event) {
      event.preventDefault();
  
      let body = document.body;
      body.classList.toggle('sidebar-enable');
  
      if (window.innerWidth >= 576) {
        if (body.dataset.leftbarCompactMode === 'condensed') {
          body.dataset.leftbarCompactMode = undefined;
          document.cookie = 'sidebar_collapsed=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        } else {
          body.dataset.leftbarCompactMode = 'condensed';
          document.cookie = 'sidebar_collapsed=true;path=/';
        }
      }
    });
  }
})

document.addEventListener('turbo:before-fetch-response', function(event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    let redirectLocation = event.detail.fetchResponse.response.headers.get('turbo_location')
    if (redirectLocation) {
      event.preventDefault()
      Turbo.clearCache()
      Turbo.visit(redirectLocation)
    }
  }
})