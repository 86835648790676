import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'submitButton' ]

  submit(event) {
    event.preventDefault()

    this.submitButtonTarget.disabled = true

    let form = this.formTarget

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    })
    .then(response => Promise.all([response.status, response.text()]))
    .then(([status, html]) => {
      if (status == 204) {
        this.removeItself()
      } else {
        this.element.outerHTML = html
      }
    })
    .catch(error => {
      console.warn(error)
    });
  }

  removeItself() {
    let speed = 500;
    let seconds = 0.5;
    this.element.style.transition = "opacity " + seconds + "s ease";
    this.element.style.opacity = 0;

    let that = this;

    setTimeout(function() {
      that.element.remove();
    }, speed);
  }
}