import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  connect() {
    this.element['apex_series'] = this;
    this.dispatchEvent('connect-series')
  }

  toggle(e) {
    e.preventDefault();
    if (this.inputTarget.checked) {
      this.dispatchEvent('add-series')
    } else {
      this.dispatchEvent('remove-series')
    }
  }

  renderActive() {
    this.inputTarget.checked = true
  }

  renderInactive() {
    this.inputTarget.checked = false
  }

  dispatchEvent(action) {
    this.element.dispatchEvent(new Event(action, { bubbles: true }))
  }
}