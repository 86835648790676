import { Controller } from '@hotwired/stimulus'
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/ranges'
import moment from 'moment';

export default class extends Controller {
  static values = {
    singleDate: { type: Boolean, default: false },
    minDate: { type: String, default: '10/01/2017' },
    maxDate: String
  }

  connect() {
    this.initializePicker()
  }

  initializePicker() {
    const initialValue = this.element.value
    const minDate = new Date(this.minDateValue);
    const maxDate = (this.hasMaxDateValue ? new Date(this.maxDateValue) : new Date());

    let options = {
      element: this.element,
      startDate: this.element.dataset.startDate,
      endDate: this.element.dataset.endDate,
      minDate: minDate,
      maxDate: maxDate,
      format: 'MM/DD/YYYY',
      resetButton: true,
      setup: (picker) => {
        picker.on('selected', (date1, date2) => {
          this.element.dispatchEvent(new Event('litepicker-selected'))
        });
      },
    }

    if (this.singleDateValue) {
      Object.assign(options, {
        singleMode: true
      })
    } else {
      Object.assign(options, {
        plugins: ['ranges'],
        numberOfMonths: 2,
        numberOfColumns: 2,
        ranges: {
          customRanges: {
            'Today': [moment().toDate(), moment().toDate()],
            'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
            'Last 365 Days': [moment().subtract(365, 'days').toDate(), moment().toDate()],
            'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
            'This Year': [moment().startOf('year').toDate(), moment().toDate()],
            'Last Year': [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()],
            'All Time': [minDate, moment().toDate()]
          }
        }
      })
    }

    new Litepicker(options);

    // hack but I can't figure how to override the default logic in litepicker
    this.element.value = initialValue
  }
}