import { Controller } from '@hotwired/stimulus'
import Modal from 'bootstrap/js/dist/modal'

export default class extends Controller {
  connect() {
    let existingModalBackdrops = document.querySelectorAll('.modal-backdrop')
    if (existingModalBackdrops.length > 0) {
      existingModalBackdrops.forEach(el => el.remove());
    }

    this.modalElement = new Modal(this.element, {})
    this.modalElement.toggle()
    this.setEventListeners()
  }

  disconnect() {
    let that = this
    this.element.removeEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  setEventListeners() {
    let that = this
    this.element.addEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  hide() {
    this.modalElement.hide()
  }

  dismissModal() {
    this.modalElement.dispose()
    this.element.closest('turbo-frame').src = undefined
    this.element.remove();
  }
}